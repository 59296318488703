@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  animation: marquee 10s linear infinite;
}
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .whitespace-nowrap {
    white-space: nowrap;
  }
  
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @keyframes scroll-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .animate-scroll-left {
    animation: scroll-left 20s linear infinite;
  }
  
  .animate-scroll-right {
    animation: scroll-right 20s linear infinite;
  }
  

  /* Container styling */
.logos-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100px; /* Adjust based on your design */
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

/* Scrolling wrapper */
.logos-scroll {
  display: flex;
  gap: 16px; /* Space between images */
  animation: scroll 10s linear infinite; /* Adjust duration for speed */
}

/* Image styling */
.logo {
  height: 80px; /* Adjust to your preference */
  flex-shrink: 0; /* Prevent shrinking */
}

/* Infinite scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-scroll-left {
  animation: scroll-left 20s linear infinite;
  display: flex;
  width: calc(200%); /* Adjust width for seamless looping */
}

.animate-scroll-right {
  animation: scroll-right 20s linear infinite;
  display: flex;
  width: calc(200%);
}


/* Keyframes for marquee animation */
@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Apply the animation */
.animate-marquee {
  display: inline-block;
  animation: marquee 10s linear infinite; /* Adjust the speed with duration */
}

/* Responsive Text and Spacing */
@media (min-width: 640px) {
  .animate-marquee {
    animation-duration: 15s; /* Slow down on larger screens */
  }
}

@media (min-width: 1024px) {
  .animate-marquee {
    animation-duration: 20s; /* Slow further for large screens */
  }
}
